import React, { useMemo, useState, useCallback } from "react";
import {
  confirmDialog,
  customFormDialog,
  HeaderTerri,
  IconTables,
  InfoTable,
  useDebounce,
  useOrderByState,
} from "src/commons";
import { useAccountDAO, useAccountLibbyFetch } from "src/app/business/Account";
import School from "@material-ui/icons/School";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/styles";
import { ModalCreateUser, ModalTableUser } from "./components";
import { column } from "./helpers/column";
import { Account } from "src/app";

const TableUserStyles = makeStyles(() => ({
  iconButton: {
    padding: 0,
    background: "#EFEFFF",
    borderRadius: 10,
    width: 24,
    height: 24,
    "&:disabled": {
      background: "#EFEFFF",
    },
  },
  iconStyle: {
    fontSize: 16,
    color: "#5E5CE6",
  },
  iconStyleDisabled: {
    fontSize: 16,
    color: "lightgrey",
  },
}));

export const TableUser = () => {
  const [search, setSearch] = useState("");
  const searchDebounced = useDebounce(search, 1000);
  const classes = TableUserStyles();

  const accountDAO = useAccountDAO();

  const { orderBy, direction, onOrderByDirectionChange } = useOrderByState(
    "idusuario",
    "desc"
  );

  const filterSearch = useMemo(
    () => ({
      personaFilter: [
        { path: "persona.nombre", value: searchDebounced, method: "includes" },
        {
          path: "persona.apellido",
          value: searchDebounced,
          method: "includes",
        },
      ],
    }),
    [searchDebounced]
  );

  const { data, working, fetchMore, reFetch } = useAccountLibbyFetch({
    limit: 20,
    direction,
    orderBy,
    filter: filterSearch,
  });

  const handleToAssign = useCallback(
    async (usuario) => {
      await customFormDialog.show({
        title: "Asignar establecimientos",
        renderComponent: (
          <ModalTableUser usuario={usuario} reFetchTable={reFetch} />
        ),
        sizeWidth: "md",
      });
    },
    [reFetch]
  );

  const handleCreateUser = useCallback(
    async (usuario, edit) => {
      const { email, idusuario, persona = {} } = usuario;

      const formValues = {
        name_user: persona.nombre,
        surname: persona.apellido,
        email,
        iddependence: usuario.id_dependence,
        sexo: persona.sexo,
        type_document: persona?.tipodocumento?.idtipodocumento,
        document: persona.documento,
        date: persona.fechanacimiento,
        rol: usuario.rol?.idrol || null,
      };
      await customFormDialog.show({
        title: edit ? "Editar usuario" : "Crear usuario",
        renderComponent: (
          <ModalCreateUser
            isValues={formValues}
            idusuario={idusuario}
            idperson={persona.idpersona}
            reFetch={reFetch}
          />
        ),
        sizeWidth: "sm",
      });
    },
    [reFetch]
  );

  const handleDisabledUser = useCallback(
    async (usuario: Account) => {
      const textPrimary = usuario.disableduser ? "Habilitar" : "Deshabilitar";
      const confirm = await confirmDialog.show({
        title: "",
        confirmText: textPrimary,
        cancelText: "Cancelar",
        content: `¿Desea ${textPrimary} al usuario ${usuario.persona.nombre} ${usuario.persona.apellido}?`,
        alternativeColor: usuario.disableduser,
      });
      if (confirm) {
        const userDisabled = {
          ...usuario,
          idusuario: usuario.idusuario,
          disableduser: usuario.disableduser ? false : true,
        };

        await accountDAO.aspect("aspect-web").save(userDisabled);
        reFetch();
      }
    },
    [accountDAO, reFetch]
  );

  const icoTables = useCallback(
    (usuario: Account) => [
      {
        text: "Editar",
        icon: (
          <Edit
            className={
              usuario.disableduser
                ? classes.iconStyleDisabled
                : classes.iconStyle
            }
          />
        ),
        handleOnClick: () => handleCreateUser(usuario, "edit"),
        disabled: usuario.disableduser,
        class: classes.iconButton,
      },
      {
        text: "Asignar",
        icon: (
          <School
            className={
              usuario.disableduser
                ? classes.iconStyleDisabled
                : classes.iconStyle
            }
          />
        ),
        handleOnClick: () => handleToAssign(usuario),
        disabled: usuario.disableduser,
        class: classes.iconButton,
      },
      {
        text: usuario.disableduser ? "Habilitar" : "Deshabilitar",
        icon: <Delete className={classes.iconStyle} />,
        handleOnClick: () => handleDisabledUser(usuario),
        disabled: false,
        class: classes.iconButton,
      },
    ],
    [handleToAssign, classes, handleCreateUser, handleDisabledUser]
  );

  const tableRowsData = useMemo(
    () =>
      data?.map((usuarios, index) => ({
        name: `${usuarios?.persona?.nombre} ${usuarios?.persona?.apellido}`,
        document: usuarios.persona.documento,
        rol: usuarios.rol.descripcion,
        icon: <IconTables content={icoTables(usuarios)} />,
        customStyles: { background: usuarios.disableduser ? "#dfecec" : "transparent"}
      })),
    [data, icoTables]
  );

  return (
    <HeaderTerri
      rightLayout
      searchValue={search}
      setSearchValue={setSearch}
      titleButton="Crear usuario"
      handleButton={() => handleCreateUser({}, "")}
      placeholder="Buscar usuario"
    >
      <InfoTable
        columns={column}
        rowIdKey="idusuario"
        rows={tableRowsData || []}
        working={working}
        orderBy={orderBy}
        onBottomScroll={fetchMore}
        direction={direction}
        onSortChange={onOrderByDirectionChange}
      />
    </HeaderTerri>
  );
};
