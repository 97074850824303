import React, { useState, useMemo, useCallback } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { customFormDialog, useDebounce } from "src/commons";
import { InfoList, InfoDrawer, HeaderTerri } from "src/commons/components";
import {
  useEstablishmentLibbyFetch,
  useEstablishmentVisitsLibbyFetch,
} from "src/app/business";
import {Establecimientos,} from "src/app/models";
import FilterListIcon from "@material-ui/icons/FilterList";
import Typography from "@material-ui/core/Typography";
import { InformationRow } from "./components/InformationRow/InformationRow";
import { EstablecimientoVisita } from "src/app/models/business/EstablecimientoVisitas";
import { filterEstablishVisits } from "./helpers";
import { ModalFilterEstablishmentList } from "./components/ModalFilterEstablishmentList";
import { filterEstablishmentList } from "./helpers/filterEstablishmentList";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
  },
  titleSchool: {
    fontSize: 24,
  },
  informationReclaim: {
    background: "#F5F5F5",
    padding: 10,
    marginTop: 15,
  },
  headerTerri: {
    borderBottom: "1px solid #e9e9e9",
  },
  informationAdditionalFont: {
    fontSize: 14,
    color: "#5E5CE6",
    textTransform: "uppercase",
  },
  iconStyle: {
    color: "#5E5CE6",
    marginRight: 5,
  },
  listEstablishmentTitle: {
    fontSize: 20,
  },
  listEstablishmentSubtitle: {
    fontSize: 14,
    marginTop: 3,
  },
  customStyleRoot: {
    maxHeight: 500,
  },
  containerCustomStyle: {
    padding: "0px 10px",
  },
  dateTitle: {
    fontSize: 20,
  },
  dateTitleSub: {
    fontSize: 14,
  },
  en_curso: {
    color: "green",
  },
  feedback_pendiente: {
    color: "#C77700",
    background: "#feeceb",
    padding: 3,
    display: "inline",
    fontSize: 12,
  },
  feedback_disponible: {
    color: "#3B873E",
    background: "#feeceb",
    padding: 3,
    display: "inline",
    fontSize: 12,
  },
}));

export const EstablishmentList = () => {
  const classes = useStyles();
  const history = useHistory();
  const [rowSelect, setRowSelect] = useState<any>(null);
  const [filterModal, setFilterModal] = useState<any>({
    nivel: "",
    distrito: "",
    comuna: "",
    barrio: "",
  });
  const [search, setSearch] = useState("");
  const searchDebounced = useDebounce(search, 1000);

  const [state, setState] = useState<any>({
    position: "left",
    open: false,
  });

  const statusStyle: any = useMemo(
    () => ({
      1: classes.en_curso,
      2: classes.feedback_pendiente,
      3: classes.feedback_disponible,
    }),
    [classes]
  );

  const filterEstablishmentVisits = useMemo(
    () => filterEstablishVisits(rowSelect),
    [rowSelect]
  );

  const filter = useMemo(
    () => filterEstablishmentList(searchDebounced, filterModal),
    [searchDebounced, filterModal]
  );

  const handleModalFilter = useCallback(async () => {
    const res = await customFormDialog.show({
      title: "Filtro de establecimientos",
      renderComponent: <ModalFilterEstablishmentList values={filterModal} />,
      sizeWidth: "sm",
    });
    setFilterModal(res);
  }, [filterModal]);

  const {
    data: establishment = [],
    working: workingEstablishment,
    fetchMore: fetchMoreEstablishment,
  } = useEstablishmentLibbyFetch({
    limit: 10,
    orderBy: "nombre",
    direction: "asc",
    filter,
  });

  const establishmentData = useMemo(
    () =>
      establishment.reduce((acum: any, establishments) => {
        establishments.localizacion.forEach((item) => {
          acum.push({
            idestablecimiento: establishments.idestablecimiento,
            title: item?.descripcion || establishments?.nombre,
            subtitle: `${item?.domicilio?.calle} ${item?.domicilio?.altura} | CP: ${item?.domicilio?.codigopostalbarrio.codigopostal}`,
            img: item?.imagenfachada,
            localizacion: item,
            distritoescolar: establishments.distritoescolar,
            ...item,
          });
        });
        return acum;
      }, []),
    [establishment]
  );

  const {
    data: establishmentVisits = [],
    working: workingEstablishmentVisits,
    fetchMore: fetchMoreEstablishmentVisits,
  } = useEstablishmentVisitsLibbyFetch({
    limit: 20,
    filter: filterEstablishmentVisits,
    orderBy: "idestablecimientovisita",
    direction: "desc",
  });

  const establishmentVisitsData = useMemo(
    () =>
      establishmentVisits.map((item, index) => ({
        title: item.establecimiento.nombre,
        subtitle: (
          <Typography className={statusStyle[item.estadovisita.idestadovisita]}>
            {item.estadovisita.descripcion}
          </Typography>
        ),
        dateLeft: (
          <>
            <Typography className={classes.dateTitle}>
              {moment(item.fechainiciovisita).format("D")}
            </Typography>
            <Typography className={classes.dateTitleSub}>
              {moment(item.fechainiciovisita).format("MMM")}
            </Typography>
          </>
        ),
        ...item,
      })),
    [establishmentVisits, classes, statusStyle]
  );

  const activesFilter =
    !!Object.values(filterModal).filter((e) => e).length &&
    Object.values(filterModal).filter((e) => e).length;
  return (
    <Grid>
      <HeaderTerri
        rightLayout
        searchValue={search}
        setSearchValue={setSearch}
        titleButton={`Filtros ${activesFilter || ""}`}
        handleButton={handleModalFilter}
        typeButton="filter"
        placeholder="Buscar por nombre o CUE"
        iconLeft={<FilterListIcon />}
        size="filter"
      >
        <InfoList
          rows={establishmentData}
          onBottomScroll={fetchMoreEstablishment}
          working={workingEstablishment}
          rowSelect={rowSelect}
          messageNoData="No se ha encontrado ningún establecimiento que cumpla con los criterios de búsqueda seleccionados"
          rowIdKey="idestablecimiento"
          onRowClick={(establishment: Establecimientos) =>
            setRowSelect(establishment)
          }
        >
          {(rowSelect: any) =>
            rowSelect && <InformationRow row={rowSelect} setState={setState} />
          }
        </InfoList>
      </HeaderTerri>
      <InfoDrawer drawerState={state} setDrawerState={setState}>
        <InfoList
          additionalInformation={
            <>
              <Typography className={classes.listEstablishmentTitle}>
                Historial de visitas
              </Typography>
              <Typography className={classes.listEstablishmentSubtitle}>
                de Todos los establecimientos
              </Typography>
            </>
          }
          rows={establishmentVisitsData}
          onBottomScroll={fetchMoreEstablishmentVisits}
          working={workingEstablishmentVisits}
          customStyleRoot={classes.customStyleRoot}
          containerCustomStyle={classes.containerCustomStyle}
          rowIdKey="idestablecimientovisita"
          onRowClick={(id: EstablecimientoVisita) =>
            history.push(`establecimientos-lista/${id.idestablecimientovisita}`)
          }
        />
      </InfoDrawer>
    </Grid>
  );
};
