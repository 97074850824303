export const API_URL = process.env.REACT_APP_API_URL as string;
export const GOOGLE_MAPS_API_KEY = process.env
  .REACT_APP_GOOGLE_MAPS_API_KEY as string;
export const GOOGLE_OAUTH_API_KEY = process.env.REACT_APP_GOOGLE_OAUTH_API_KEY as string;
export const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string;
export const GOOGLE_MAP_API_KEY = 'AIzaSyCMDHpXlQiX6L0s90Q7JeDDwjQkn5075IM' as string;
export const REACT_APP_GOOGLE_ANALYTICS_KEY = process.env
  .REACT_APP_GOOGLE_ANALYTICS_KEY as string;
// DEV
//export const GOOGLE_OAUTH_API_KEY = "AIzaSyDWCPDCJfqoU9YAJoz4iCZRQTMxMYO2V7A";
//export const GOOGLE_OAUTH_CLIENT_ID =
//  "863947327656-v6qb5ar1p20vvkfb65vuksfih25gvfp2.apps.googleusercontent.com";
// export const GOOGLE_MAP_API_KEY = 'AIzaSyDszJRRSlfzenwffr9lVpagB-SA_q6ma6w'; < FALTA CAMBIAR ESTA POR NUESTRA
