import React, { useCallback } from "react";
import { DatabaseConnector } from "@phinxlab/libby-rest-web";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PersonIcon from "@material-ui/icons/Person";
import { useHistory } from "react-router";
import { useProfileContext } from "src/lib/profiles/ProfileContext";
import { grey } from "../../../theme/colors";
import { LibbyObject } from "../../types";

const headerStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 25,
  },
  userContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "3px 15px",
    background: grey.lightWhite,
    cursor: "pointer",
    marginRight: 5,
    borderRadius: 24,
    "@media print": {
      display: "none",
    },
  },
  userText: {
    fontFamily: "Open Sans",
    color: grey.blueStrong,
    fontSize: 12,
    fontWeight: "bold",
    paddingRight: 10,
  },
  titleStyle: {
    fontFamily: "Open Sans",
    color: grey.medium,
    fontSize: 30,
  },
  iconStyle: {
    color: grey.blueStrong,
    fontSize: 24,
    paddingRight: 5,
  },
  arrowBackIcon: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 10,
    justifyContent: "center",
    "@media print": {
      display: "none",
    },
  },
}));

interface Title {
  title?: string;
  user?: string;
  school?: string;
  libby: LibbyObject;
  backButton?: boolean;
  backToProfile?: string;
}

const HeaderRaw = ({
  title,
  user,
  libby,
  backButton = false,
  backToProfile,
}: Title) => {
  const history = useHistory();
  const classes = headerStyles();
  const { setProfile } = useProfileContext();
  const goBack = useCallback(
    () => (backToProfile ? setProfile(backToProfile) : history.goBack()),
    [history, backToProfile, setProfile]
  );
  const navigationUserPerfil = useCallback(() => {
    history.push(`/private/perfil`);
  }, [history]);

  return (
    <div onClick={navigationUserPerfil} className={classes.container}>
      <div className={classes.arrowBackIcon}>
        {!!backButton && (
          <IconButton onClick={goBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <span className={classes.titleStyle}>{title}</span>
      </div>
      <div className={classes.userContainer}>
        <>
          <span className={classes.userText}>{user}</span>
          <PersonIcon className={classes.iconStyle} />
        </>
      </div>
    </div>
  );
};

export const Header = DatabaseConnector(HeaderRaw)();
