import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../lib/libby/generator";
import {Rol} from "../models";
import {businessOptions} from "./businessOptions";

const DAO_NAME = 'rol';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idRol',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idRol',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Rol ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.Rol,
  hoc: { ...defaultOptions.hoc, ...businessOptions.Rol.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Rol>(DAO_NAME, optionsToUse);

// hooks
export const useRolFetchById = fetchById;
export const useRolLibbyCall = libbyCall;
export const useRolDAO = libbyDAO;
export const useRolLibbyFetch = libbyFetch;

// components/hoc
export const RolAutocomplete = entityAutocomplete;
export const RolCall = renderPropCall;
export const RolById = renderPropFetchById;
export const RolSelect = simpleSelect;
export const RolAccordionSelector = accordionSelector;
export const RolSelectedChips = selectedChips;

// context
// ByIdContext
export const useRolByIdContext = useByIdContext;
export const RolByIdProvider = ByIdProvider;
export const RolByIdContext = ByIdContext;
// GeneralContext
export const useRolContext = useGeneralContext;
export const RolProvider = GeneralProvider;
export const RolContext = GeneralContext;
