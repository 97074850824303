import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, InputAdornment } from "@material-ui/core/";

interface MainInputTypesProps {
  value: string | number;
  handleChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  label?: string;
  icon?: JSX.Element;
  // maxLength?: number;
  type?: string;
  fullWidth?: boolean;
  rows?: number | string;
  multiline?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  customStyle?: any;
  customStyleLabel?: any;
  name?: string;
  maxLengths?: number;
}

export const MainInput = ({
  label,
  icon,
  fullWidth = false,
  rows = 0,
  type,
  multiline = false,
  handleChange,
  placeholder,
  required,
  disabled,
  error = false,
  helperText = "",
  value,
  customStyle,
  customStyleLabel,
  name,
  maxLengths = 40,
}: MainInputTypesProps) => {
  const inputStyles = makeStyles(() => ({
    root: {
      ...customStyle,
      padding: "7px",
    },
    label: {
      ...customStyleLabel,
    },
  }));

  const classes = inputStyles();

  return (
    <>
      {label && (
        <Typography align="left" variant="subtitle2" className={classes.label}>
          {label}
        </Typography>
      )}
      <TextField
        name={name}
        InputProps={{
          disableUnderline: true,
          startAdornment: icon && (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
          className: classes.root,
          //inputProps: { min: 0, maxLength: maxLengths },
        }}
        error={error}
        rows={rows}
        type={type}
        fullWidth={fullWidth}
        multiline={multiline}
        helperText={error && helperText}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={(_value: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(_value)
        }
        value={value}
      />
    </>
  );
};
