import React, { useState, useCallback, useMemo } from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HeaderInformation } from "../HeaderInformation";
import { ContainerInformation } from "../ContainerInformation";
import {
  useClaimAnswerDAO,
  useClaimAnswerLibbyFetch,
  useClaimDAO,
  useEstablishmentLibbyFetch,
  useEstablishmentVisitsDAO,
  useNotificationTerritoryDAO,
} from "src/app/business";
import { ReclamoTerritorio } from "src/app/models"
import { FormInformation } from "../FormInformation";
import { ListComment } from "../ListComment";
import { visitStatus } from "src/commons";

const { FEEDBACK_DISPONIBLE, FEEDBACK_PENDIENTE } = visitStatus;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
    overflow: "auto",
    maxHeight: "490px",
  },
  containerSub: {
    borderTop: "1px solid #E0E0E0",
    marginTop: 15,
  },
  listComment: {
    padding: 15,
    background: "#f5f5f5",
    marginTop: 15,
    borderRadius: 8,
    boxShadow: "0px 0px 0px 1px #E0E0E0",
  },
  itemReclaim: {
    textAlign: "right",
  },
  containerForm: {
    marginTop: 15,
  },
  title: {
    display: "flex",
    width: "100%",
  },
  subtitle: {
    display: "flex",
    marginTop: 15,
  },
}));

interface ReclamoTerri extends ReclamoTerritorio {
  title?: string;
  subtitleSecondary?: string;
  subtitleThird?: string;
}

interface InformationRowProps {
  row: ReclamoTerri;
  reFetchClaim: () => void;
  reFetchEstablishmentvisits: () => void;
  setTabActive?: any;
}

export const InformationRow = ({
  row,
  reFetchClaim,
  reFetchEstablishmentvisits,
  setTabActive,
}: InformationRowProps) => {
  const [valueMessage, setValueMessage] = useState("");
  const [hiddenForm, setHiddenForm] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const classes = useStyles();
  const filterClaimAnswer = useMemo(
    () => ({
      answer: [
        {
          path: "reclamoterritorio.idreclamoterritorio",
          value: row.idreclamoterritorio,
        },
      ],
    }),
    [row]
  );

  const filterEstablishment = useMemo(
    () => ({
      answer: [
        {
          path: "idestablecimiento",
          value: row.establecimiento.idestablecimiento,
        },
      ],
    }),
    [row]
  );

  const { data = [], reFetch } = useClaimAnswerLibbyFetch({
    filter: filterClaimAnswer,
    limit: 200,
  });

  const { data: establishment } = useEstablishmentLibbyFetch({
    filter: filterEstablishment,
    limit: 1,
  });

  const claimAnswerDAO = useClaimAnswerDAO();
  const claimDAO = useClaimDAO();
  const establishmentVisitsDAO = useEstablishmentVisitsDAO();
  const NotificationTerritoryDAO = useNotificationTerritoryDAO();

  const handleSendMessage = useCallback(async () => {
    try {
      setDisabled(true);
      if (
        row.establecimientovisita?.estadovisita?.idestadovisita ===
        FEEDBACK_PENDIENTE
      ) {
        const updateStatusVisit = {
          idestablecimientovisita:
            row.establecimientovisita.idestablecimientovisita,
          fechainiciovisita: row.establecimientovisita.fechainiciovisita,
          fechafinvisita: row.establecimientovisita.fechafinvisita,
          establecimiento: {
            idestablecimiento: row.establecimiento.idestablecimiento,
          },
          cuestionario: row.establecimientovisita.cuestionario,
          nivel: { idnivel: row?.nivel?.idnivel },
          estadovisita: { idestadovisita: FEEDBACK_DISPONIBLE },
          usuario: { idusuario: row.establecimientovisita.usuario.idusuario },
        };
        await establishmentVisitsDAO
          .aspect("aspect-web")
          .save(updateStatusVisit);
      }

      const answerPost = {
        respuesta: valueMessage,
        fecha: moment().format(),
        reclamoterritorio: { idreclamoterritorio: row.idreclamoterritorio },
      };

      const updatedClaim = {
        idreclamoterritorio: row.idreclamoterritorio,
        tienerespuesta: true,
        viewed: true,
        asunto: row.asunto,
        nivel: { idnivel: row.nivel.idnivel },
        observaciones: row.observaciones,
        nroreclamo: row.nroreclamo,
        fecha: row.fecha,
        establecimiento: {
          idestablecimiento: row.establecimiento.idestablecimiento,
        },
        arearesponsable: {
          idarearesponsable: row.arearesponsable.idarearesponsable,
        },
        arearesponsable_sub: {
          id_arearesponsable_sub:
            row.arearesponsable_sub.id_arearesponsable_sub,
        },
        establecimientovisita: {
          idestablecimientovisita:
            row.establecimientovisita.idestablecimientovisita,
        },
        tipoformulario: {
          idtipoformulario: row.tipoformulario.idtipoformulario,
        },
        usuario: { idusuario: row.usuario.idusuario },
      };
      await claimAnswerDAO.aspect("aspect-web").save(answerPost);
      await claimDAO.aspect("aspect-web").save(updatedClaim);

      const typeForm = row.tipoformulario.idtipoformulario === 1 ? 2 : 3;

      const jsonInformation = {
        id_establecimiento: row.establecimiento.idestablecimiento,
        idestablecimientovisita:
          row.establecimientovisita.idestablecimientovisita,
      };

      const text = row.tipoformulario.idtipoformulario === 1 ? "un" : "una";
      await NotificationTerritoryDAO.aspect("aspect-web").save({
        arrayUser: [
          {
            usuario: { idusuario: row.establecimientovisita.usuario.idusuario },
            esglobal: false,
            titulo: `Te han respondido ${text} ${row.subtitleThird?.toLowerCase()}`,
            mensaje: `Mesa territorial te ha respondido, Escuela: ${row.establecimiento.nombre}`,
            estado_notificacion: { id_estado_notificacion: typeForm },
            information: JSON.stringify(jsonInformation),
          },
        ],
      });
      setTabActive(1);
    } catch (e) {
      console.log(e);
    } finally {
      setDisabled(false);
      setValueMessage("");
      reFetch();
      reFetchClaim();
      reFetchEstablishmentvisits();
    }
  }, [
    valueMessage,
    claimAnswerDAO,
    row,
    reFetch,
    claimDAO,
    setTabActive,
    reFetchClaim,
    establishmentVisitsDAO,
    NotificationTerritoryDAO,
    reFetchEstablishmentvisits,
  ]);

  const establish = establishment && establishment[0];
  const headerPropsData: any = useMemo(
    () => ({
      image: establish?.localizacion[0]?.imagenfachada,
      title: row.title,
      calle: `${establish?.localizacion[0]?.domicilio.calle} ${establish?.localizacion[0]?.domicilio.altura}`,
      cp: establish?.localizacion[0]?.domicilio.codigopostalbarrio.codigopostal,
      cue: establish?.localizacion[0]?.cueanexo,
      distrito: establish?.distritoescolar.nombre,
    }),
    [row, establish]
  );

  const perfilData = useMemo(
    () => ({
      rowData: {
        title: row?.subtitleSecondary,
        fecha: moment(row.fecha).format("D-MM-YYYY"),
        subtitle: row?.subtitleThird,
      },
      rowContainer: [
        {
          key: "Asunto",
          title: row?.asunto,
        },
        {
          key: "Área responsable",
          title: row?.arearesponsable?.descripcion,
        },
        {
          key: "Sub Área Responsable",
          title: row?.arearesponsable_sub?.descripcion,
        },
        !row?.nroreclamo
          ? {}
          : {
              key: "Número de reclamo",
              title: row?.nroreclamo,
            },
        {
          key: "Observaciones",
          title: row?.observaciones,
        },
      ],
    }),
    [row]
  );

  return (
    <Grid container className={classes.container}>
      <HeaderInformation row={headerPropsData} />
      <ContainerInformation row={perfilData} />
      <Grid container className={classes.containerSub}>
        <ListComment dataComment={data} />
        <FormInformation
          value={valueMessage}
          working={disabled}
          setValue={setValueMessage}
          hiddenForm={hiddenForm}
          setHiddenForm={setHiddenForm}
          handleSendMessage={handleSendMessage}
        />
      </Grid>
    </Grid>
  );
};
