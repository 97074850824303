import { Grid, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState, useMemo, useCallback } from "react";
import { useAreaResponsabilityWebDAO } from "src/app/business/business/AreaResponsabilityWeb";
import { customFormDialog, FooterForm, MainInput } from "src/commons";

const customStyles = {
  footer: { marginTop: 20 },
};

const modalTableUserStyles = makeStyles(() => ({
  container: {
    padding: "20px 30px",
  },
}));

export const ModalCreateAreaResponsability = ({
  isValue = "",
  reFetch,
  areaSelect,
}: any) => {
  const classes = modalTableUserStyles();
  const [formValue, setFormValue] = useState(isValue);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const areaResponsableDAO = useAreaResponsabilityWebDAO();

  const handleAddArea = useCallback(async () => {
    setLoading(true);
    try {
      const areaCreate = {
        descripcion: formValue,
        disabled: false,
      };

      const areaEdit = {
        ...areaSelect,
        ...areaCreate,
      };

      const areaResponsable = !areaSelect.idarearesponsable ? areaCreate : areaEdit;
      const textArea = !areaSelect.idarearesponsable
        ? "Se ha creado una nueva Área Responsable con exito!"
        : "Se ha modificado el Área Responsable con exito!";
      await areaResponsableDAO.aspect("aspect-web").save(areaResponsable);

      enqueueSnackbar(textArea, {
        variant: "success",
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Se ha producido un error intentelo nuevamente", {
        variant: "error",
      });
    } finally {
      reFetch();
      setLoading(false);
      customFormDialog.handleCancel();
    }
  }, [
    areaResponsableDAO,
    formValue,
    enqueueSnackbar,
    setLoading,
    reFetch,
    areaSelect,
  ]);

  const buttonConfig = useMemo(
    () => [
      {
        title: "Cancelar",
        handleOnClick: () => customFormDialog.handleCancel(),
        type: "secondary",
        size: "small",
      },
      {
        title: "Guardar",
        handleOnClick: () => handleAddArea(),
        size: "small",
        disabled: !formValue || loading,
      },
    ],
    [formValue, handleAddArea, loading]
  );

  return (
    <Grid container className={classes.container}>
      <MainInput
        handleChange={(e) => setFormValue(e.target.value)}
        value={formValue}
        placeholder="Nombre del área"
        fullWidth
      />
      <FooterForm
        buttonConfig={buttonConfig}
        spacing={1}
        customStyle={customStyles.footer}
      />
    </Grid>
  );
};
