import React, { useState, useMemo, useCallback } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { customFormDialog, useDebounce } from "src/commons";
import { InfoList, InfoDrawer, HeaderTerri } from "src/commons/components";
import {
  useClaimLibbyFetch,
  useEstablishmentVisitsLibbyFetch,
} from "src/app/business";
import {Establecimientos} from "src/app/models";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";

import FilterListIcon from "@material-ui/icons/FilterList";
import HistoryIcon from "@material-ui/icons/History";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Typography from "@material-ui/core/Typography";
import { InformationRow } from "./components/InformationRow";
import { filterClaim, filterEstablishmentVisit, tabCustom } from "./helpers";
import { ModalFilterClaim } from "./components/ModalFilterClaim";
import { EstablecimientoVisita } from "src/app/models/business/EstablecimientoVisitas";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
  },
  titleSchool: {
    fontSize: 24,
  },
  informationReclaim: {
    background: "#F5F5F5",
    padding: 10,
    marginTop: 15,
  },
  headerTerri: {
    borderBottom: "1px solid #e9e9e9",
  },
  informationAdditionalFont: {
    fontSize: 14,
    color: "#5E5CE6",
    textTransform: "uppercase",
  },
  iconStyle: {
    color: "#5E5CE6",
    marginRight: 5,
  },
  listEstablishmentTitle: {
    fontSize: 20,
  },
  listEstablishmentSubtitle: {
    fontSize: 14,
    marginTop: 3,
  },
  customStyleRoot: {
    maxHeight: 500,
  },
  containerCustomStyle: {
    padding: "0px 10px",
  },
  dateTitle: {
    fontSize: 20,
  },
  dateTitleSub: {
    fontSize: 14,
  },
  en_curso: {
    color: "green",
  },
  feedback_pendiente: {
    color: "#C77700",
    background: "#feeceb",
    padding: 3,
    display: "inline",
    fontSize: 12,
  },
  feedback_disponible: {
    color: "#3B873E",
    background: "#feeceb",
    padding: 3,
    display: "inline",
    fontSize: 12,
  },
}));

interface paramsTypeo {
  idestablecimiento?: string | null;
  tipoformulario?: string;
  idestablecimientovisita?: any;
}

export const ReclaimObservationList = () => {
  const {
    idestablecimiento = null,
    tipoformulario = "",
    idestablecimientovisita = null,
  }: paramsTypeo = useParams();

  const [rowSelect, setRowSelect] = useState<Establecimientos | null>(null);
  const [answerSelect, setAnswerSelect] = useState(0);
  const [search, setSearch] = useState("");
  const [filterEstablishment, setFilterEstablishment] = useState<number | null>(
    idestablecimientovisita
  );

  console.log(answerSelect)
  const [filterModal, setFilterModal] = useState<any>({
    fecha: "",
    area: "",
    nivel: "",
    tipo: tipoformulario,
  });
  const searchDebounced = useDebounce(search, 1000);
  const [drawer, setDrawer] = useState<{ position: string; open: boolean }>({
    position: "left",
    open: false,
  });
  const classes = useStyles();
  const statusStyle: any = useMemo(
    () => ({
      1: classes.en_curso,
      2: classes.feedback_pendiente,
      3: classes.feedback_disponible,
    }),
    [classes]
  );

  const filter = useMemo(
    () =>
      filterClaim(
        searchDebounced,
        answerSelect,
        filterEstablishment,
        filterModal,
        idestablecimiento
      ),
    [
      searchDebounced,
      answerSelect,
      filterEstablishment,
      filterModal,
      idestablecimiento,
    ]
  );

  const handleModalFilter = useCallback(async () => {
    const res = await customFormDialog.show({
      title: "Filtro de informes",
      renderComponent: <ModalFilterClaim values={filterModal} />,
      sizeWidth: "sm",
    });
    setFilterModal(res);
  }, [filterModal]);

  const fechaOrder = !filterModal?.fecha ? "desc" : undefined;
  const {
    data = [],
    working: workingClaim,
    fetchMore,
    reFetch,
  } = useClaimLibbyFetch({
    filter,
    limit: 10,
    orderBy: "fecha",
    direction: fechaOrder,
  });

  const {
    data: establishmentVisits = [],
    working: workingEstablishmentVisits,
    fetchMore: fetchMoreEstablishmentVisits,
    reFetch: reFetchEstablishmentvisits,
  } = useEstablishmentVisitsLibbyFetch({
    limit: 20,
    orderBy: "idestablecimientovisita",
    direction: "desc",
    filter: filterEstablishmentVisit,
  });

  const establishmentVisitsData = useMemo(
    () =>
      establishmentVisits.map((item, index) => ({
        title: item.establecimiento.nombre,
        subtitle: (
          <Typography className={statusStyle[item.estadovisita.idestadovisita]}>
            {item.estadovisita.descripcion}
          </Typography>
        ),
        dateLeft: (
          <>
            <Typography className={classes.dateTitle}>
              {moment(item.fechainiciovisita).format("D")}
            </Typography>
            <Typography className={classes.dateTitleSub}>
              {moment(item.fechainiciovisita).format("MMM")}
            </Typography>
          </>
        ),
        ...item,
      })),
    [establishmentVisits, classes, statusStyle]
  );

  const reclaimObservationData = useMemo(
    () =>
      data.map((item, index) => ({
        idestablecimiento: item.idreclamoterritorio,
        title: item.establecimiento.nombre,
        subtitle: item.observaciones,
        subtitleSecondary: `Por ${item.usuario.persona.nombre} ${item.usuario.persona.apellido}`,
        subtitleThird: item.tipoformulario.descripcion,
        dateRight: moment(item.fecha).format("D/MM"),
        icon:
          item.tipoformulario.idtipoformulario === 1 ? (
            <ErrorOutlineIcon />
          ) : (
            <VisibilityIcon />
          ),
        ...item,
      })),
    [data]
  );

  const activesFilter =
    !!Object.values(filterModal).filter((e) => e).length &&
    Object.values(filterModal).filter((e) => e).length;
  return (
    <Grid>
      <HeaderTerri
        rightLayout
        leftLayout
        searchValue={search}
        setSearchValue={setSearch}
        titleButton={`Filtros ${activesFilter || ""}`}
        handleButton={handleModalFilter}
        setTabActive={setAnswerSelect}
        tabActive={answerSelect}
        customStyles={classes.headerTerri}
        tabCustom={tabCustom}
        typeButton="filter"
        placeholder="Buscar por N° de reclamo o CUE"
        iconLeft={<FilterListIcon />}
        size="filter"
      >
        <InfoList
          rows={reclaimObservationData}
          onBottomScroll={fetchMore}
          working={workingClaim}
          rowSelect={rowSelect}
          messageNoData="No hay informes para esta visita"
          rowIdKey="idestablecimiento"
          additionalInformation={
            <>
              {!idestablecimiento && !idestablecimientovisita && (
                <>
                  {filterEstablishment ? (
                    <HighlightOffIcon className={classes.iconStyle} />
                  ) : (
                    <HistoryIcon className={classes.iconStyle} />
                  )}
                  <Typography className={classes.informationAdditionalFont}>
                    {filterEstablishment
                      ? "Limpiar filtro"
                      : "Historial de visitas"}
                  </Typography>
                </>
              )}
            </>
          }
          onInfoClick={
            !idestablecimiento &&
            !idestablecimientovisita &&
            filterEstablishment
              ? () => {
                  setFilterEstablishment(null);
                  setDrawer({ position: "left", open: true });
                }
              : () => setDrawer({ position: "left", open: true })
          }
          onRowClick={(row: Establecimientos) => setRowSelect(row)}
        >
          {(rowSelect: any) =>
            rowSelect && (
              <InformationRow
                row={rowSelect}
                reFetchClaim={reFetch}
                reFetchEstablishmentvisits={reFetchEstablishmentvisits}
                setTabActive={setAnswerSelect}
              />
            )
          }
        </InfoList>
      </HeaderTerri>
      <InfoDrawer drawerState={drawer} setDrawerState={setDrawer}>
        <InfoList
          additionalInformation={
            <>
              <Typography className={classes.listEstablishmentTitle}>
                Historial de visitas
              </Typography>
              <Typography className={classes.listEstablishmentSubtitle}>
                de Todos los establecimientos
              </Typography>
            </>
          }
          rows={establishmentVisitsData}
          onBottomScroll={fetchMoreEstablishmentVisits}
          working={workingEstablishmentVisits}
          customStyleRoot={classes.customStyleRoot}
          containerCustomStyle={classes.containerCustomStyle}
          rowIdKey="idestablecimientovisita"
          onRowClick={(id: EstablecimientoVisita) =>
            setFilterEstablishment(id.idestablecimientovisita)
          }
        />
      </InfoDrawer>
    </Grid>
  );
};
