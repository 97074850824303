import React from "react";
import { Grid, Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useForm, useField } from "react-final-form-hooks";
import { NivelSelect, ResponsableAreaSelect } from "src/app/business";
import { customFormDialog, FooterForm } from "src/commons";
import { FormDatePicker } from "src/lib/templates";
import { primary } from "src/theme/colors";

const customStyles = {
  footer: { marginTop: 20 },
  containerGrid: { paddingRight: 20, paddingLeft: 20, paddingBottom: 17 },
};

const materialStyles = makeStyles(() => ({
  datePicker: {
    paddingLeft: 0,
    "& .MuiOutlinedInput-root": {
      border: `1px solid ${primary.white}`,
    },
  },
}));

interface Values {
  fecha?: string;
  area?: string;
  tipo?: number;
}

interface ModalFilterClaimProps {
  values: Values | {}
}

export const ModalFilterClaim = ({ values }: ModalFilterClaimProps) => {
  const classes = materialStyles();
  const formValues = values;

  const { form, handleSubmit } = useForm({
    initialValues: formValues,
    onSubmit: (values) => customFormDialog.handleConfirm(values),
  });

  const dateForm = useField("fecha", form);
  const areaResponsableForm = useField("area", form);
  const radioForm = useField("tipo", form);
  const nivelForm = useField("nivel", form);


  const arrSelect = [
    {
      component: FormDatePicker,
      label: "Fecha de nacimiento",
      placeholder: "dd/mm/aaaa",
      name: dateForm.input.name,
      onChange: dateForm.input.onChange,
      value: dateForm.input.value,
      form: form,
      fullWidth: true,
      disableFuture: true,
      classNames: classes.datePicker,
    },
    {
      component: ResponsableAreaSelect,
      title: "Área responsable",
      placeholder: "Área responsable",
      name: areaResponsableForm.input.name,
      handleChange: areaResponsableForm.input.onChange,
      value: areaResponsableForm.input.value,
      valueKey: "idarearesponsable",
    },
    {
      component: NivelSelect,
      title: "Nivel",
      placeholder: "Nivel",
      name: nivelForm.input.name,
      handleChange: nivelForm.input.onChange,
      value: nivelForm.input.value,
      valueKey: "idnivel",
    },
  ];

  const buttonConfig = [
    {
      title: "Cancelar",
      handleOnClick: () => customFormDialog.handleCancel(),
      type: "secondary",
      size: "small",
    },
    {
      title: "Limpiar",
      handleOnClick: () => customFormDialog.handleCancel(""),
      type: "cancel",
      size: "small",
    },
    {
      title: "Aplicar",
      handleOnClick: () => null,
      size: "small",
      typeButton: "submit",
    },
  ];

  return (
    <Grid container style={{ width: "95%", margin: "auto", marginTop: 10 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} style={customStyles.containerGrid}>
          {arrSelect.map((e: any) => (
            <Grid item xs={6}>
              {e.component({ ...e })}
            </Grid>
          ))}
          <Grid style={{ marginLeft: 10 }}>
            <Radio
              checked={parseInt(radioForm.input.value) === 1}
              value={1}
              onChange={radioForm.input.onChange}
            />
            Reclamo
            <Radio
              checked={parseInt(radioForm.input.value) === 2}
              value={2}
              onChange={radioForm.input.onChange}
            />
            Observación
          </Grid>
          <FooterForm
            buttonConfig={buttonConfig}
            spacing={1}
            customStyle={customStyles.footer}
          />
        </Grid>
      </form>
    </Grid>
  );
};
