import moment from "moment";

export const filterClaim = (
  search: string,
  answerSelect: number,
  filterEstablishment: any,
  filterModal: any,
  idestablecimiento: any
) => {
  const filter = {
    visit: [
      {
        path: "establecimientovisita.estadovisita.idestadovisita",
        value: 1,
        method: "notEquals",
      },
    ],
    answer: [
      {
        path: "tienerespuesta",
        value: !!answerSelect,
      },
    ],
    establishmentVisit: !filterEstablishment
      ? {}
      : [
          {
            path: "establecimientovisita.idestablecimientovisita",
            value: filterEstablishment,
          },
        ],
    modal: !filterModal.fecha
      ? {}
      : [
          {
            path: "fecha",
            value: moment(filterModal?.fecha).format("YYYY-MM-DD"),
            method: "higher",
          },
        ],
    area: !filterModal.area
      ? {}
      : [
          {
            path: "arearesponsable.idarearesponsable",
            value: filterModal?.area,
          },
        ],
    nivel: !filterModal.nivel
      ? {}
      : [
          {
            path: "nivel.idnivel",
            value: filterModal?.nivel,
          },
        ],
    tipo: !filterModal.tipo
      ? {}
      : [{ path: "tipoformulario.idtipoformulario", value: filterModal?.tipo }],
    establecimiento: !idestablecimiento
      ? {}
      : [
          {
            path: "establecimiento.idestablecimiento",
            value: idestablecimiento,
          },
        ],
  };

  const searchFilter = [
    {
      path: "asunto",
      value: search,
      method: "includes",
    },
    {
      path: "observaciones",
      value: search,
      method: "includes",
    },
    {
      path: "nroreclamo",
      value: search,
      method: "includes",
    },
    {
      path: "establecimiento.nombre",
      value: search,
      method: "includes",
    },
    {
      path: "establecimiento.cue",
      value: search,
      method: "includes",
    },
    {
      path: "usuario.persona.nombre",
      value: search,
      method: "includes",
    },
    {
      path: "usuario.persona.apellido",
      value: search,
      method: "includes",
    },
  ];

  return {
    searchFilter,
    ...filter,
  };
};
