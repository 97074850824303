import React, { useMemo, useState, useCallback } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import {
  customFormDialog,
  FooterForm,
  InfoTable,
  useDebounce,
} from "src/commons";
import { makeStyles } from "@material-ui/styles";
import {
  EstablishmentAutoComplete,
  useUserEstablishmentDAO,
  useUserEstablishmentLibbyFetch,
} from "src/app/business";
import {  Account, Establecimientos,} from "src/app/models";
import { useAccountDAO } from "src/app/business/Account";
import { UsuarioEstablecimiento } from "src/app/models/business/UsuarioEstablecimiento";
import { columns } from "./helpers/columns";

const modalTableUserStyles = makeStyles(() => ({
  container: {
    padding: "20px 30px",
  },
  containerForm: {
    alignItems: "center",
  },
  allEstablish: {
    marginTop: 5,
    textAlign: "center",
  },
  tableContainer: {
    maxHeight: "350px",
  },
}));

const customStyles = {
  footer: { marginTop: 20 },
};

interface ModalTableUserProps {
  usuario: Account;
  reFetchTable: () => void;
}

export const ModalTableUser = ({
  usuario,
  reFetchTable,
}: ModalTableUserProps) => {
  const classes = modalTableUserStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [checkbox, setCheckbox] = useState(usuario?.admin === 1);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const searchDebounced = useDebounce(inputValue, 1000);

  const filterUserEstablishment = useMemo(
    () => ({
      id: checkbox
        ? [{}]
        : [
            {
              path: "idusuario",
              value: usuario?.idusuario,
            },
          ],
    }),
    [usuario, checkbox]
  );

  const {
    data: dataEstablishment,
    working: workingEstablishment,
    fetchMore,
    reFetch,
  } = useUserEstablishmentLibbyFetch({
    filter: filterUserEstablishment,
    orderBy: "idusuarioestablecimiento",
    direction: "asc",
  });

  const userEstablishmentDAO = useUserEstablishmentDAO();

  const handleAddEstablishmentUser = useCallback(
    async (establishment: Establecimientos) => {
      try {
        setLoading(true);
        if (
          dataEstablishment?.some(
            (item, index) =>
              item.localizacion.idlocalizacion ===
              establishment.localizacion.idlocalizacion
          )
        ) {
          throw new Error("Establecimiento repetido");
        }
        //TODO: verificar cueanexo quizas tengas que guarda por idlocalizacion es lo mismo cueanexo pero no es array
        const assignEstablishment = {
          idusuario: usuario.idusuario,
          cueanexo: establishment.localizacion.cueanexo,
          localizacion: {
            idlocalizacion: establishment.localizacion.cueanexo,
          },
        };
        await userEstablishmentDAO
          .aspect("aspect-web")
          .save(assignEstablishment);
        reFetch();
        enqueueSnackbar("Se ha asignado el establecimiento con exito", {
          variant: "success",
        });
      } catch (e) {
        console.log(e);
        enqueueSnackbar("Se ha producido un error intentelo nuevamente", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [userEstablishmentDAO, usuario, reFetch, dataEstablishment, enqueueSnackbar]
  );

  const accountDAO = useAccountDAO();

  const handleViewAllEstablishment = useCallback(
    async (e) => {
      try {
        setLoading(true);
        setCheckbox(e.target.checked);

        const { id_area_responsable, ...data} = usuario

        const objectUser = {
          ...data,
          admin: e.target.checked ? 1 : 0,
        };
        await accountDAO.aspect("aspect-web").save(objectUser);
        enqueueSnackbar("Se ha asignado todos los establecimiento con exito", {
          variant: "success",
        });
        reFetchTable();
      } catch (e) {
        console.log(e);
        enqueueSnackbar("Se ha producido un error intentelo nuevamente", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [accountDAO, usuario, setCheckbox, enqueueSnackbar, reFetchTable]
  );

  const handleDeleteEstablishment = useCallback(
    async (establishment: UsuarioEstablecimiento) => {
      try {
        setLoading(true);
        await userEstablishmentDAO.remove({
          idusuarioestablecimiento: establishment.idusuarioestablecimiento,
        });
        reFetch();
        enqueueSnackbar("Se ha eliminado con exito", {
          variant: "success",
        });
      } catch (e) {
        console.log(e);
        enqueueSnackbar("Se ha producido un error intentelo nuevamente", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [userEstablishmentDAO, enqueueSnackbar, reFetch]
  );

  const tableRowsData = useMemo(
    () =>
      dataEstablishment?.map((item, index) => ({
        establishment:
          item.localizacion?.descripcion ||
          item.localizacion?.establecimiento?.nombreabreviado ||
          item.localizacion.establecimiento.nombre,
        cue: item.localizacion.establecimiento.cue,
        direction: `${item.localizacion.domicilio.calle} ${item.localizacion.domicilio.altura}`,
        icon: (
          <IconButton onClick={() => handleDeleteEstablishment(item)}>
            <CloseIcon />
          </IconButton>
        ),
      })),
    [dataEstablishment, handleDeleteEstablishment]
  );

  const filterEstablishmentCue = useMemo(
    () => ({
      id: !searchDebounced.length
        ? [{}]
        : [
            {
              path: "cue",
              value: searchDebounced,
              method: "includes",
            },
            {
              path: "localizacion.idlocalizacion",
              value: searchDebounced,
              method: "includes",
            },
          ],
    }),
    [searchDebounced]
  );

  const buttonConfig = [
    {
      title: "Cerrar",
      handleOnClick: () => customFormDialog.handleCancel(),
      typeButton: "submit",
      size: "small",
    },
  ];

  return (
    <Grid container className={classes.container}>
      <Grid container className={classes.containerForm}>
        <Grid item xs={6}>
          <EstablishmentAutoComplete
            value=""
            filter={filterEstablishmentCue}
            label="Seleccionar establecimientos"
            setInputValue={setInputValue}
            disabled={checkbox}
            loading={loading}
            onChange={(establishment: Establecimientos) =>
              handleAddEstablishmentUser(establishment)
            }
          />
        </Grid>
        <Grid item xs={6} className={classes.allEstablish}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkbox}
                onChange={(e) => handleViewAllEstablishment(e)}
                name="checkedB"
                color="primary"
                disabled={loading}
              />
            }
            label="Ver todos los establecimientos"
          />
        </Grid>
      </Grid>
      <Grid container>
        <InfoTable
          rows={tableRowsData || []}
          columns={columns}
          working={workingEstablishment}
          rowIdKey="idusuarioestablecimiento"
          onBottomScroll={fetchMore}
          customStyle={classes.tableContainer}
        />
        {checkbox && (
          <Typography>
            El usuario podrá visualizar todos los establecimientos
          </Typography>
        )}
      </Grid>
      <FooterForm
        buttonConfig={buttonConfig}
        spacing={1}
        customStyle={customStyles.footer}
      />
    </Grid>
  );
};
