import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { MainButton } from "../MainButton";
import { MainInput } from "../MainInput";

const HeaderTerriStyles = makeStyles(() => ({
  searchStyle: {
    marginLeft: 10,
    marginRight: 10,
  },
  containerSearch: {
    paddingTop: 20,
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 20,
  }
}));

export const HeaderTerri = ({
  children,
  searchValue,
  setSearchValue,
  leftLayout = false,
  rightLayout = false,
  handleButton,
  titleButton,
  tabActive,
  setTabActive,
  customStyles = {},
  tabCustom = [],
  placeholder,
  typeButton = "primary",
  iconLeft = false,
  hiddenButton,
  size = "large"
}: any) => {
  const classes = HeaderTerriStyles();

  return (
    <Grid container>
      <Grid container className={`${classes.containerSearch} ${customStyles}`}>
        <Grid item xs={6}>
          {leftLayout && (
            <Tabs
              value={tabActive}
              onChange={(event, newValue) => setTabActive(newValue)}
              indicatorColor="primary"
              textColor="primary"
              aria-label="icon tabs example"
            >
              {tabCustom.map((item: { title: string }, index: number) => (
                <Tab label={item.title} key={index}/>
              ))}
            </Tabs>
          )}
        </Grid>
        <Grid item xs={6}>
          {rightLayout && (
            <Grid container justify="flex-end">
              <Grid item xs={6} className={classes.searchStyle}>
                <MainInput
                  fullWidth
                  handleChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                  placeholder={placeholder}
                />
              </Grid>
              {!hiddenButton && <Grid item className={classes.button}>
                <MainButton
                  handleOnClick={handleButton}
                  title={titleButton}
                  type={typeButton}
                  iconLeft={iconLeft}
                  size={size}
                />
              </Grid>}
            </Grid>
          )}
        </Grid>
      </Grid>
      {children}
    </Grid>
  );
};
