import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MainButton, MainInput } from "src/commons";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
    overflow: "auto",
    maxHeight: "490px",
  },
  containerSub: {
    borderTop: "1px solid #E0E0E0",
    marginTop: 15,
  },
  listComment: {
    padding: 15,
    background: "#f5f5f5",
    marginTop: 15,
    borderRadius: 8,
    boxShadow: "0px 0px 0px 1px #E0E0E0",
  },
  itemReclaim: {
    textAlign: "right",
  },
  containerForm: {
    marginTop: 15,
  },
  title: {
    display: "flex",
    width: "100%",
  },
  subtitle: {
    display: "flex",
    marginTop: 15,
  },
}));

interface FormInformationProps {
  value: string;
  setValue: (value: any) => void;
  hiddenForm: boolean;
  handleSendMessage: () => void;
  setHiddenForm: (value: boolean) => void;
  working: boolean
}

export const FormInformation = ({
  value,
  setValue,
  hiddenForm,
  handleSendMessage,
  setHiddenForm,
  working,
}: FormInformationProps) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.containerForm} justify="flex-end">
      {hiddenForm && (
        <MainInput
          fullWidth
          rows={5}
          multiline
          handleChange={(e) => setValue(e.target.value)}
          value={value}
          placeholder="Escribí tu respuesta"
          maxLengths={600}
        />
      )}
      <MainButton
        disabled={hiddenForm ? !value.length || working : false}
        title={hiddenForm ? "Enviar" : "Responder"}
        handleOnClick={
          hiddenForm ? handleSendMessage : () => setHiddenForm(true)
        }
        customStyle={{ marginTop: 10 }}
      />
    </Grid>
  );
};
