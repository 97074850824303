import { Grid, Radio } from "@material-ui/core";
import React from "react";

export const RadioPrimary = () => {
  return (
    <Grid>
      <Radio />
      Si
      <Radio />
      No
    </Grid>
  );
};
