import React, { useState, useMemo, useCallback } from "react";
import { Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import { EstablishmentAutoComplete } from "src/app/business";
import { Establecimientos } from "src/app/models";
import { InfoTable, useDebounce } from "src/commons";
import { columns } from "./helpers";

const modalTableUserStyles = makeStyles(() => ({
  container: {
    padding: "20px 30px",
  },
  containerForm: {
    alignItems: "center",
  },
  allEstablish: {
    marginTop: 5,
    textAlign: "center",
  },
  tableContainer: {
    maxHeight: "350px",
  },
}));

interface SelectEstablishmentProps {
  setEstablishmentSelect: (user: any) => void;
  establishmentSelect: any;
  loading: boolean;
}

export const SelectEstablishment = ({
  setEstablishmentSelect,
  establishmentSelect,
  loading,
}: SelectEstablishmentProps) => {
  const classes = modalTableUserStyles();
  const [inputValue, setInputValue] = useState("");
  const searchDebounced = useDebounce(inputValue, 1000);

  const handleAddEstablishment = useCallback(
    (establishment) => {
      if (establishment) {
        setEstablishmentSelect(establishment);
      }
    },
    [setEstablishmentSelect]
  );

  const filterEstablishmentCue = useMemo(
    () => ({
      id: !searchDebounced.length
        ? [{}]
        : [
            {
              path: "cue",
              value: searchDebounced,
              method: "includes",
            },
          ],
    }),
    [searchDebounced]
  );
  const rowsData = useMemo(
    () =>
      establishmentSelect.map((item: any) => ({
        ...item,
        icon: (
          <IconButton onClick={() => handleAddEstablishment(item)}>
            <CloseIcon />
          </IconButton>
        ),
      })),
    [establishmentSelect, handleAddEstablishment]
  );

  return (
    <Grid container>
      <Grid container className={classes.containerForm}>
        <Grid item xs={6}>
          <EstablishmentAutoComplete
            value=""
            filter={filterEstablishmentCue}
            label="Seleccionar establecimientos"
            setInputValue={setInputValue}
            loading={loading}
            onChange={(establishment: Establecimientos) =>
              handleAddEstablishment(establishment)
            }
          />
        </Grid>
        <Grid container>
          <InfoTable
            rows={rowsData || []}
            columns={columns}
            rowIdKey="idestablecimiento"
            customStyle={classes.tableContainer}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
