import { useMemo } from "react";
import {
  useActiveTeachersLibbyFetch,
  useEspecialidadLibbyFetch,
  useEstablishmentFutureLibbyFetch,
  useEstablishmentPriorityLibbyFetch,
  useFacilitadorIntecLibbyFetch,
  useInfoPersonalNoDocentLibbyFetch,
  usePadronLibbyFetch,
  useSchoolGreenLibbyFetch,
  useSectionLibbyFetch,
  useStudentViewLibbyFetch,
  useTeacherViewLibbyFetch,
  useTransportSchoolLibbyFetch,
} from "src/app/business";
import { useStudentMovementLibbyFetch } from "src/app/business/business/StudentMovement";

export const useFetchInformationRow = (row: any) => {
  const filtroLocalizacionEstablecimiento = useMemo(
    () => ({
      visit: [
        {
          path: "localizacion.establecimiento.idestablecimiento",
          value: row?.idestablecimiento,
        },
      ],
    }),
    [row]
  );

  const filtroEstablecimientoId = useMemo(
    () => ({
      visit: [
        {
          path: "idestablecimiento",
          value: row?.idestablecimiento,
        },
      ],
    }),
    [row]
  );

  const filtroEstablecimientoCue = useMemo(
    () => ({
      visit: [
        {
          path: "cue",
          value: row?.idestablecimiento,
        },
      ],
    }),
    [row]
  );

  const filtroPlanta = useMemo(
    () => ({
      visit: [
        {
          path: "planta.seccion.localizacion.establecimiento.idestablecimiento",
          value: row?.idestablecimiento,
        },
      ],
    }),
    [row]
  );

  const filterSeccion = useMemo(
    () => ({
      visit: [
        {
          path: "seccion.localizacion.establecimiento.idestablecimiento",
          value: row?.idestablecimiento,
        },
      ],
    }),
    [row]
  );

  const filterSeccionActive = useMemo(
    () => ({
      visit: !row
        ? [{}]
        : [
            {
              path: "seccion.localizacion.establecimiento.idestablecimiento",
              value: row?.idestablecimiento,
            },
            {
              path: "idestadoalumno",
              value: 1,
            },
          ],
    }),
    [row]
  );

  const mapLocation = useMemo(
    () => ({
      path: "cueanexo",
      value: row?.idlocalizacion,
    }),
    [row]
  );

  const filterActiveTeacher = useMemo(
    () => ({
      visit: !row
        ? [{}]
        : [
            {
              path: "tipo_de_cargo",
              value: "Conducción",
            },
            {
              path: "situacion_de_revista",
              value: "Titular",
            },
            mapLocation,
          ],
    }),
    [row, mapLocation]
  );

  const {
    data: sectionData = [],
    working: workingSection,
  } = useSectionLibbyFetch({
    limit: 10000,
    filter: filtroLocalizacionEstablecimiento,
  });

  const {
    data: especialidad = [],
    working: workingEspecialidad,
  } = useEspecialidadLibbyFetch({
    limit: 10000,
    filter: filtroEstablecimientoCue,
  });

  const {
    data: intec = [],
    working: workingIntec,
  } = useFacilitadorIntecLibbyFetch({
    limit: 10000,
    filter: filtroEstablecimientoCue,
  });

  const {
    data: establishmentFutureData = [],
    working: workingEstablishmentFuture,
  } = useEstablishmentFutureLibbyFetch({
    limit: 1,
    filter: filtroEstablecimientoId,
  });

  const {
    data: establishmentPriority = [],
    working: workingEstablishmentPriority,
  } = useEstablishmentPriorityLibbyFetch({
    limit: 3,
    filter: filtroEstablecimientoId,
  });

  const {
    data: schoolGreen = [],
    working: workingSchoolGreen,
  } = useSchoolGreenLibbyFetch({
    limit: 1,
    filter: filtroEstablecimientoCue,
  });

  const {
    data: infoPersonalNoDocent,
    working: workingInfoPersonalNoDocent,
  } = useInfoPersonalNoDocentLibbyFetch({
    filter: filtroEstablecimientoCue,
  });

  const {
    data: studentView = [],
    //working: workingStudentView,
  } = useStudentViewLibbyFetch({
    limit: 10000,
    filter: filterSeccion,
  });

  const {
    data: transportSchool = [],
    working: workigTransportSchool,
  } = useTransportSchoolLibbyFetch({
    limit: 10000,
    filter: filtroEstablecimientoId,
  });

  const {
    data: studentMovement = [],
    //working: workingStudent,
  } = useStudentMovementLibbyFetch({
    limit: 10000,
    filter: filterSeccionActive,
  });

  const {
    data: teacherView = [],
    //working: workingTeacherView,
  } = useTeacherViewLibbyFetch({
    limit: 300,
    filter: filtroPlanta,
  });

  const { data: padronData = [], working: workingPadron } = usePadronLibbyFetch(
    {
      limit: 10000,
      filter: filtroEstablecimientoCue,
    }
  );

  //TODO: directos, vicedirector, etc
  const {
    data: activeTeachers,
    //working: workingActiveTeachers,
  } = useActiveTeachersLibbyFetch({
    filter: filterActiveTeacher,
    limit: 10000,
  });

  return {
    sections: sectionData,
    establishmentFutureData,
    schoolGreen,
    padronData,
    studentMovement,
    studentView,
    establishmentPriority,
    activeTeachers,
    transportSchool,
    infoPersonalNoDocent,
    teacherView,
    intec,
    especialidad,
    loading:
      workingSection ||
      workingEstablishmentFuture ||
      workingSchoolGreen ||
      workingPadron ||
      workingInfoPersonalNoDocent ||
      workigTransportSchool ||
      workingEstablishmentPriority ||
      workingEspecialidad ||
      workingIntec,
  };
};
