import React, { useMemo } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { ContainerInformation } from "../../../ReclaimObservation/ReclaimObservationList/components/ContainerInformation";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
    overflow: "auto",
    maxHeight: "490px",
  },
}));

export const InformationRow = ({ row }: any) => {
  const classes = useStyles();

  const perfilData = useMemo(
    () => ({
      rowData: {
        title: row?.subtitleSecondary,
        fecha: moment(row.fecha).format("D-MM-YYYY | HH:mm"),
        subtitle: row?.subtitleThird,
      },
      rowContainer: [
        {
          key: "Destinatario",
          title: row?.esglobal
            ? "Todos los funcionarios"
            : row?.usuario.map(
                (item: any, index: any) =>
                  `${item?.persona.nombre} ${item?.persona.apellido}, `
              ),
        },
        {
          key: "Asunto",
          title: row?.titulo,
        },
        {
          key: "Mensaje",
          title: row?.mensaje,
        },
      ],
    }),
    [row]
  );

  return (
    <Grid container className={classes.container}>
      <ContainerInformation row={perfilData} />
    </Grid>
  );
};
