import React from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
  },
  root: {
    width: "100%",
  },
  informationReclaim: {
    padding: 15,
    background: "#f5f5f5",
    marginTop: 15,
    borderRadius: 8,
    boxShadow: "0px 0px 0px 1px #E0E0E0",
  },
  itemReclaim: {
    textAlign: "right",
  },
  title: {
    fontSize: 12,
    color: "#696969",
  },
  headerTitle: {
    fontSize: 13,
    color: "#696969",
  },
  subtitle: {
    fontSize: 15,
    color: "#000000",
  },
  typeReclaim: {
    color: "#B23842",
    border: "1px solid #B23842",
    display: "inline",
    padding: 5,
    borderRadius: "30px",
    fontSize: 13,
  },
}));

export const ContainerInformation = ({ row }: any) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.informationReclaim}>
      <Grid container>
        <Grid item xs={4}>
          <Typography className={classes.headerTitle}>
            {row.rowData.title}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.headerTitle}>
            <CalendarTodayIcon className={classes.headerTitle} />{" "}
            {row.rowData.fecha}
          </Typography>
        </Grid>
        <Grid item xs={5} className={classes.itemReclaim}>
          <Typography className={classes.typeReclaim}>
            {row.rowData.subtitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <List className={classes.root}>
          {row.rowContainer.map((item: any, index: any) => (
            <ListItem alignItems="flex-start" disableGutters>
              <ListItemText
                primary={
                  <Typography className={classes.title}>{item.key}</Typography>
                }
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.subtitle}
                      color="textPrimary"
                    >
                      {item.title}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};
